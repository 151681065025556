import { React } from "react";

function Cards(props) {
  return (
    <div className="card product_item">
      <div className="body">
        {props.media_url ? (
          <div className="cp_img dd">
            <img
              src={props.media_url}
              alt={props.title}
              className="img-fluid"
            />
          </div>
        ) : (
          ""
        )}

        <div className="product_details">
          <h5>
            <p>{props.title} </p>
          </h5>
        </div>
      </div>
    </div>
  );
}
// <div className="col-lg-6 col-md-4 col-sm-12">
//   <div className="card product_item">
//     <div className="body">
//       <div className="cp_img">
//         <img src={bg} alt="Product" className="img-fluid" />
//       </div>
//       <div className="product_details">
//         <h5>
//           <a href="ec-product-detail.html">test </a>
//         </h5>
//       </div>
//     </div>
//   </div>
// </div>;

// const CardsSection = styled.div`
//   .dd {
//     text-align-last: center;
//   }
//   img {
//     width: 70% !important;
//     height: 70% !important;
//   }
//   .card {
//     margin: 10px !important;
//     --tw-bg-opacity: 1 !important;
//     background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
//     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
//       0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
//     border-radius: 0.75rem;
//     box-sizing: border-box;
//     border: 0 solid #eee;
//   }
// `;
export default Cards;

// portfolios
//   .slice(0)
//   .reverse()
//   .map((portfolio, index) => {
//     return (
//       <div key={index} className="col-lg-6 col-md-4 col-sm-12">
//         <div className="card product_item">
//           <div className="body">
//             <div className="h-75 d-sm-block cp_img">
//               <img
//                 src={portfolio.media_url}
//                 alt="Product"
//                 className="img-thumbnail "
//               />
//             </div>
//             <div className="h-25 d-inline-block product_details">
//               <h5>
//                 <a href="ec-product-detail.html">{portfolio.title}</a>
//               </h5>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   });
