import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


import global_en from "./translations/en/global.json"
import global_ar from "./translations/ar/global.json"

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources: {
        en: {
            global: global_en
        },
        ar: {
            global: global_ar
        }
    },
    fallbackLng: 'en',
    debug:false,
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  // Function to change language
export function changeLanguage(lng) {
  i18n.changeLanguage(lng);
  localStorage.setItem('i18nextLng', lng); // Manually setting the localStorage
}

  export default i18n;