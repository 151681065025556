import { React, useState, useEffect } from "react";
import { useAuth } from "../../../context/Auth";
import { supabase } from "../../../supabaseClient";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

import PortfolioMedia from "./PortfolioMedia";

function Portfolio() {
  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("");
  const [website, setWebsite] = useState("");
  const [portfolios, setPortfolios] = useState([]);
  const [media_url, setMediaUrl] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const { user } = useAuth();

  let history = useHistory();
  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`portfolios`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data.portfolios) {
          setPortfolios(data.portfolios);
        } else {
          setPortfolios([]);
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  async function updatePortolio({ title, about, website, media_url }) {
    if (!title) {
      setError(true);
      setMessage("Title is mandatory");
    } else if (!media_url) {
      setError(true);
      setMessage("Image is mandatory");
    } else {
      setError(false);
      setMessage("Experience added successfully");
      try {
        setLoading(true);
        if (media_url) {
        }
        const updates = {
          id: user.id,
          portfolios: [
            ...portfolios,
            {
              id: uuidv4(),
              title: title,
              about: about,
              website,
              media_url,
              updated_at: new Date(),
            },
          ],
          updated_at: new Date(),
        };

        let { error } = await supabase
          .from("portfolio")
          .upsert(updates, {
            returning: "minimal", // Don't return the value after inserting
          })
          .then(history.push(`/dashboard/${user.id}/portfolios`));

        if (error) {
          throw error;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  async function uploadImage(url) {
    try {
      const { publicURL, error } = await supabase.storage
        .from("portfoliomedia")
        .getPublicUrl(url);
      if (error) {
        throw error;
      }

      setMediaUrl(publicURL);
    } catch (error) {
      console.log("Error downloading image: ", error.message);
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Add a new Portfolio</h1>
      </div>
      <label className="form-label px-0">Minimized picture</label>

      <PortfolioMedia
        url={media_url}
        size={150}
        onUpload={(url) => {
          uploadImage(url);
        }}
      />

      <form>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Add a title</label>
            <input
              className="form-control"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>
        {/* <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">
              Add a brief description of the work
            </label>
            <textarea
              placeholder="..."
              className="form-control"
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
            />
          </div>
        </div> */}
        {/* <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">رابط العمل</label>
            <input
              className="form-control"
              value={website}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
            />
          </div>
        </div> */}
        {handleAlerts()}
        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() =>
            updatePortolio({
              title,
              about,
              website,
              media_url,
            })
          }
          disabled={loading}
          type="submit"
        >
          {loading ? "Loading ..." : "Update"}
        </button>
      </form>
    </div>
  );
}

export default Portfolio;
