import React from "react";

function Pricebox({ priceboxItem, home }) {
  const { icon, title, content, price, badge, best, url } = priceboxItem;
  return (
    <div
      className={
        best
          ? "price-item bg-white rounded shadow-dark text-center best"
          : "price-item bg-white rounded shadow-dark text-center"
      }
    >
      {badge && <span className="badge">{badge}</span>}
      {home ? (
        <img src={icon} alt="Regular" onError={(i) => (i.target.src = "")} />
      ) : null}
      <h2 className="plan">{title}</h2>
      {content.map((singleContent) => (
        <p key={singleContent.id}>{singleContent.text}</p>
      ))}
      {/* <h3 className="price">
        <em>$</em>
        {price}
        <span>Month</span>
      </h3> */}
      {home || priceboxItem.id === 1 ? null : (
        <a href={`${url}`} target="_blank" className="btn btn-default">
          Select Plan
        </a>
      )}
    </div>
  );
}

export default Pricebox;
