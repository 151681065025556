import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { supabase } from "../../supabaseClient";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css";
import App from "./App";
import Homepage from "./pages/Homepage";

import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header from "./components/layouts/Header";
import About from "./components/sections/About";
import Blogs from "./components/sections/Blogs";
import Brandlogos from "./components/sections/Brandlogos";
import Contact from "./components/sections/Contact";
import Experiences from "./components/sections/Experiences";
import Herosection from "./components/sections/Herosection";
import Pricing from "./components/sections/Pricing";
import Services from "./components/sections/Services";
import Testimonials from "./components/sections/Testimonials";
import Works from "./components/sections/Works";

function UserPage() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  let { slug } = useParams();
  const [plan, setPlan] = useState(1);
  const [about, setAbout] = useState([]);
  const [Avatar, setAvatar] = useState(null);
  const [experiences, setExperiences] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [contact, setContact] = useState([]);
  const [layout, setLayout] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let array = [];

    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select("*")
          .eq("username", slug)
          .single();

        if (error && status !== 406) {
          console.log("error", error);
          throw error;
        }

        if (data.plandata) {
          setPlan(data.plandata);
        }

        if (data.about) {
          setAbout(data.about);
          if (data.about.media_url) {
            setAvatar(data.about.media_url);
          }
        }

        if (data.experiences) {
          setExperiences(data.experiences);
        }

        if (data.portfolios) {
          setPortfolios(data.portfolios);
        }
        if (data.reviews) {
          setReviews(data.reviews);
        }
        if (data.contact) {
          console.log("layout", data.contact);
          setContact(data.contact);
        }
        if (data.layout) {
          setLayout(data.layout);
        }
      } catch (error) {
        console.log("error");
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [slug]);
  return (
    <div style={{ background: layout.backgroundColor }}>
      <Header
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
        background={layout.mainColor}
        plan={plan}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection
              name={about.name}
              instagram={contact.instagram}
              twitter={contact.twitter}
              behance={contact.behance}
              dribbble={contact.dribbble}
              pinterest={contact.pinterest}
              linkedin={contact.linkedin}
              background={layout.mainColor}
              sliderString1={layout.sliderString1}
              sliderString2={layout.sliderString2}
              sliderString3={layout.sliderString3}
            />
          </ReactCursorPosition>
        </Element>
        {about ? (
          <Element name="section-about">
            <About
              aboutDescription={about.about}
              avatar={Avatar}
              projects={layout.projects}
              clients={layout.clients}
              acomplishments={layout.acomplishments}
              coffee={layout.coffee}
            />
          </Element>
        ) : null}
        {plan === 1 ? null : (
          <>
            {experiences.length > 0 ? (
              <Element name="section-experiences">
                <Experiences experienceData={experiences} />
              </Element>
            ) : null}
          </>
        )}
        {portfolios.length > 0 ? (
          <Element name="section-works">
            <Works portfolios={portfolios} />
          </Element>
        ) : null}
        {/* <Element name="section-pricing">
          <Pricing />
        </Element> */}
        {plan === 1 || plan === 2 || reviews.length === 0 ? null : (
          <Element name="section-testimoninal">
            <Testimonials reviews={reviews} />
          </Element>
        )}
        {/* <Element name="section-services">
          <Services />
        </Element> */}
        {/* <Element name="section-brandlogos">
          <Brandlogos />
        </Element>
        <Element name="section-blogs">
          <Blogs />
        </Element> */}
        {contact ? (
          <Element name="section-contact">
            <Contact email={contact.email} phone={contact.phone} />
          </Element>
        ) : (
          ""
        )}

        <div className="spacer" data-height="96"></div>
      </main>
    </div>
  );
}

export default UserPage;
