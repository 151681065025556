import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AboutMe from "./components/dashboard/AboutMe";
import Dashboard from "./components/dashboard/Dashboard.js";
import Home from "./components/home/Home";
import Login from "./components/Login";
import Portfolios from "./components/dashboard/portfolio/Portfolios";
import { AuthProvider } from "./context/Auth";
import { Signup } from "./components/Signup";
import { PrivateRoute } from "./components/PrivateRoute";
import Account from "./components/dashboard/Account";
import Portfolio from "./components/dashboard/portfolio/AddPortfolio";
import Experience from "./components/dashboard/Experience";
import Reviews from "./components/dashboard/customerReview/Reviews";
import AddReview from "./components/dashboard/customerReview/AddReview";
import ContactMe from "./components/dashboard/ContactMe";
import PageStyle from "./components/dashboard/PageStyle";
import Plan from "./components/dashboard/Plan";
import UserPage from "./components/userPage/UserPage";

function App() {
  return (
    <div>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard/:id"
              as="/dashboard/:id/account"
            >
              <Dashboard>
                <Account />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/aboutme">
              <Dashboard>
                <AboutMe />
              </Dashboard>
            </PrivateRoute>

            <PrivateRoute path="/dashboard/:id/experience">
              <Dashboard>
                <Experience />
              </Dashboard>
            </PrivateRoute>

            <PrivateRoute path="/dashboard/:id/portfolios">
              <Dashboard>
                <Portfolios />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/addportfolio">
              <Dashboard>
                <Portfolio />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/reviews">
              <Dashboard>
                <Reviews />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/addreview">
              <Dashboard>
                <AddReview />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/contact">
              <Dashboard>
                <ContactMe />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/pagestyle">
              <Dashboard>
                <PageStyle />
              </Dashboard>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/:id/plan">
              <Dashboard>
                <Plan />
              </Dashboard>
            </PrivateRoute>

            <Route path="/signup" component={Signup} />
            <Route path="/login">
              <Login />
            </Route>
            <Route exact path="/:slug">
              <UserPage />
            </Route>

            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
