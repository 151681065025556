import { React, useState, useEffect } from "react";
import { useAuth } from "../../../context/Auth";
import { supabase } from "../../../supabaseClient";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

import ReviewMedia from "./ReviewMedia";

function AddReview() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [review, setReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const [media_url, setMediaUrl] = useState(null);

  const { user } = useAuth();

  let history = useHistory();
  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`reviews`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data.reviews) {
          setReviews(data.reviews);
        } else {
          setReviews([]);
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  async function updatePortolio({ name, description, review, media_url }) {
    try {
      setLoading(true);
      if (media_url) {
      }
      const updates = {
        id: user.id,
        reviews: [
          ...reviews,
          {
            id: uuidv4(),
            name: name,
            description: description,
            review,
            media_url,
            updated_at: new Date(),
          },
        ],
        updated_at: new Date(),
      };

      let { error } = await supabase
        .from("portfolio")
        .upsert(updates, {
          returning: "minimal", // Don't return the value after inserting
        })
        .then(history.push("/dashboard/[id]/reviews"));

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function uploadImage(url) {
    try {
      const { publicURL, error } = await supabase.storage
        .from("reviewmedia")
        .getPublicUrl(url);
      if (error) {
        throw error;
      }

      setMediaUrl(publicURL);
    } catch (error) {
      console.log("Error downloading image: ", error.message);
    }
  }

  const [loading, setLoading] = useState(false);
  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Add a review</h1>
      </div>
      <label className="form-label px-0">photo</label>

      <ReviewMedia
        url={media_url}
        size={150}
        onUpload={(url) => {
          uploadImage(url);
        }}
      />

      <form>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">name of the Customer</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>

        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">
              description about the Customer
            </label>
            <input
              placeholder="manager, developer ..."
              className="form-control"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">the review content</label>
            <textarea
              placeholder="..."
              className="form-control"
              value={review}
              onChange={(e) => {
                setReview(e.target.value);
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() =>
            updatePortolio({
              name,
              description,
              review,
              media_url,
            })
          }
          disabled={loading}
          type="submit"
        >
          {loading ? "loading ..." : "add"}
        </button>
      </form>
    </div>
  );
}

export default AddReview;
