import React, { useState } from "react";
import "./Dashboard.css";
import { Link, useHistory } from "react-router-dom";
import { supabase } from "../../supabaseClient";

import { useAuth } from "../../context/Auth";
import Footer from "../userPage/components/layouts/Footer";

export default function Dashboard({ children }) {
  const { user, signOut } = useAuth();
  const history = useHistory();
  const [username, setUsername] = React.useState();
  const [avatar_url, setAvatarUrl] = React.useState(null);
  const [plan, setPlan] = React.useState(1);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  React.useEffect(() => {
    getProfile();
  }, [user]);

  async function getProfile() {
    try {
      let { data, error, status } = await supabase
        .from("portfolio")
        .select(`username, avatar_url,plandata`)
        .eq("id", user.id)
        .single();

      if (error && status !== 406) {
        setMessage("Username must be added first");
      }

      if (data.username) {
        setUsername(data.username);
        setError(false);
      }
      if (data.username === null) {
        setMessage("Username must be added first");

        setError(true);
      }

      if (data.avatar_url) {
        downloadImage(data.avatar_url);
      }
      if (data.plandata) {
        setPlan(data.plandata);
      }
    } catch (error) {
      setMessage("Username must be added first");
    } finally {
    }
  }

  async function downloadImage(path) {
    try {
      const { data, error } = await supabase.storage
        .from("avatars")
        .download(path);
      if (error) {
        throw error;
      }
      const url = URL.createObjectURL(data);
      setAvatarUrl(url);
    } catch (error) {
      console.log("Error downloading image: ", error.message);
    }
  }
  async function handleSignOut() {
    await signOut();

    history.push("/");
  }
  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  return (
    <>
      <div className="position-relative bg-gradient">
        <div className="shape shape-bottom shape-slant bg-secondary d-none d-lg-block">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 260">
            <polygon
              fill="currentColor"
              points="0,257 0,260 3000,260 3000,0"
            ></polygon>
          </svg>
        </div>{" "}
      </div>
      <div
        className="container position-relative zindex-5 pb-4 mb-md-3"
        style={{ marginTop: "-350px" }}
      >
        <div className="row">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="bg-light rounded-3 shadow-lg">
              <div className="px-4 py-4 mb-1 text-center">
                <img
                  className="d-block rounded-circle mx-auto my-2"
                  src={avatar_url}
                  at={username ? username : "..."}
                  width="110"
                />
                {handleAlerts()}

                <h6 className="mb-0 pt-1">{username ? username : ""}</h6>
                {username ? (
                  <div className="mt-4">
                    <Link
                      className="btn btn-default"
                      to={`/${username}`}
                      duration={500}
                      target="_blank"
                    >
                      My Page
                    </Link>
                  </div>
                ) : null}
                {/* <span className="text-muted fs-sm">faisal1</span> */}
              </div>

              <div className="d-lg-none px-4 pb-4 text-center">
                <a
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  className="btn btn-primary px-5 mb-2 collapsed"
                >
                  <i className="ai-menu me-2"></i>List
                </a> 
              </div>
              <div
                className={`d-lg-block collapse pb-2 ${
                  isCollapsed ? "" : "show"
                }`}
              >
                <h3 className="d-block bg-secondary fs-sm fw-semibold text-muted mb-0 px-4 py-3">
                  Dashboard
                </h3>
                <Link
                  className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                  to={`/dashboard/${user.id}/aboutme`}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <i className="ai-shopping-bag fs-lg opacity-60 me-2"></i>
                  About me<span className="nav-indicator"></span>
                </Link>

                {plan === 1 ? null : (
                  <Link
                    className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                    to={`/dashboard/${user.id}/experience`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    <i className=" fs-lg opacity-60 me-2"></i>
                    My experiences
                  </Link>
                )}

                <Link
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                  to={`/dashboard/${user.id}/portfolios`}
                >
                  <i className=" fs-lg opacity-60 me-2"></i>
                  My works
                </Link>
                {plan === 1 || plan === 2 ? null : (
                  <Link
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                    to={`/dashboard/${user.id}/reviews`}
                  >
                    <i className=" fs-lg opacity-60 me-2"></i>Customer reviews
                  </Link>
                )}

                <Link
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                  to={`/dashboard/${user.id}/contact`}
                >
                  <i className=" fs-lg opacity-60 me-2"></i>
                  Contact
                </Link>

                {plan === 1 ? null : (
                  <Link
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                    to={`/dashboard/${user.id}/pagestyle`}
                  >
                    <i className=" fs-lg opacity-60 me-2"></i>
                    Page design
                  </Link>
                )}

                <h3 className="d-block bg-secondary fs-sm fw-semibold text-muted mb-0 px-4 py-3">
                  Account settings
                </h3>
                <Link
                  className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                  to={`/dashboard/${user.id}`}
                >
                  <i className="ai-dollar-sign fs-lg opacity-60 me-2"></i>
                  Account information
                </Link>
                <Link
                  className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                  to={`/dashboard/${user.id}/plan`}
                >
                  <i className="ai-dollar-sign fs-lg opacity-60 me-2"></i>
                  Plan
                </Link>
                <a
                  onClick={handleSignOut}
                  className="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                >
                  <i className="ai-dollar-sign fs-lg opacity-60 me-2"></i>
                  sign out
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="d-flex flex-column h-100 bg-light rounded-3 shadow-lg p-4">
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
