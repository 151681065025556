import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";
import CountUp from "react-countup";
import styled from "styled-components";

function About({
  name,
  avatar,
  aboutDescription,
  projects,
  clients,
  acomplishments,
  coffee,
}) {
  const winWidth = window.innerWidth;
  const countQuery = (count) => {
    if (winWidth && winWidth > 767) {
      return <CountUp end={count} />;
    }
    return <CountUp end={count} />;
  };

  return (
    <section id="about">
      <div className="container">
        <Pagetitle title=" About me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              {avatar ? <Avatar src={avatar} alt={name} /> : ""}
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div>
                  <p>{aboutDescription}</p>
                  {/* <div className="mt-3">
                    <a href="!#" className="btn btn-default">
                      Download CV
                    </a>
                  </div> */}
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                {/* <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {/* {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))} */}
          <div className="col-md-3 col-sm-6 col-6">
            {projects ? (
              <div className="fact-item">
                <span className="icon icon-fire"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0 number">
                    <em className="count">{countQuery(projects)}</em>
                  </h3>
                  <p className="mb-0">Projects</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-3 col-sm-6 col-6">
            {clients ? (
              <div className="fact-item">
                <span className="icon icon-people"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0 number">
                    <em className="count">{countQuery(clients)}</em>
                  </h3>
                  <p className="mb-0">Customers</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-3 col-sm-6 col-6">
            {acomplishments ? (
              <div className="fact-item">
                <span className="icon icon-badge"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0 number">
                    <em className="count">{countQuery(acomplishments)}</em>
                  </h3>
                  <p className="mb-0">Certificates and Awards</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-3 col-sm-6 col-6">
            {coffee ? (
              <div className="fact-item">
                <span className="icon icon-cup"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0 number">
                    <em className="count">{countQuery(coffee)}</em>
                  </h3>
                  <p className="mb-0">Cup of coffee</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

const Avatar = styled.img`
  max-width: 120px !important;
`;
export default About;
