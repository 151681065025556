import { useEffect, useState } from "react";
import { supabase } from "../../../supabaseClient";

export default function PortfolioMedia({ url, size, onUpload }) {
  const [mediaUrl, setMediaUrl] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (url) setMediaUrl(url);
  }, [url]);

  // async function downloadImage(path) {
  //   console.log("downlowad", path);
  //   try {
  //     const { publicURL, error } = await supabase.storage
  //       .from("portfoliomedia")
  //       .getPublicUrl(path);
  //     if (error) {
  //       console.log("we", error);

  //       throw error;
  //     }

  //     setMediaUrl(publicURL);
  //   } catch (error) {
  //     console.log("Error downloading image: ", error.message);
  //   }
  // }

  async function uploadMedia(event) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      let { data, error: uploadError } = await supabase.storage
        .from("portfoliomedia")
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }
      // console.log("data image: ", data);
      onUpload(filePath);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div>
      <div className="bg-secondary rounded-3 p-4 mb-4">
        <div className="d-block d-sm-flex align-items-center">
          {mediaUrl ? (
            <img
              width="110"
              src={mediaUrl}
              alt="Avatar"
              className="d-block rounded-circle mx-sm-0 mx-auto mb-3 mb-sm-0"
            />
          ) : (
            <div
              className="avatar no-image"
              style={{ height: size, width: size }}
            />
          )}

          <div className="ps-sm-3 text-center text-sm-start">
            <label
              className="btn btn-light shadow btn-sm mb-2"
              htmlFor="single"
            >
              {uploading ? "loading ..." : "Change image"}
            </label>
            <input
              style={{
                visibility: "hidden",
                position: "absolute",
              }}
              type="file"
              id="single"
              accept="image/*"
              onChange={uploadMedia}
              disabled={uploading}
            />
            <div className="p mb-0 fs-ms text-muted">
              Upload JPG, GIF or PNG image. 300 x 300 required.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
