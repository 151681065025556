import { React, useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { supabase } from "../../supabaseClient";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import "react-widgets/styles.css";
import NumberPicker from "react-widgets/NumberPicker";

const PageStyle = () => {
  const [displayMainColor, setDisplayMainColor] = useState(false);
  const [mainColor, setMainColor] = useState("#2f504f");
  const [displayBackgroundcolor, setDisplayBackgroundcolor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  //counter
  const [projects, setProjects] = useState(0);
  const [clients, setClients] = useState(0);
  const [acomplishments, setAcomplishments] = useState(0);
  const [coffee, setCoffee] = useState(0);

  //the slider
  const [sliderString1, setSliderString1] = useState("");
  const [sliderString2, setSliderString2] = useState("");
  const [sliderString3, setSliderString3] = useState("");

  const [loading, setLoading] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`layout`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data.layout) {
          setBackgroundColor(data.layout.backgroundColor);
          setMainColor(data.layout.mainColor);
          setAcomplishments(data.layout.acomplishments);
          setCoffee(data.layout.coffee);
          setClients(data.layout.clients);
          setProjects(data.layout.projects);
          setSliderString1(data.layout.sliderString1);
          setSliderString2(data.layout.sliderString2);
          setSliderString3(data.layout.sliderString3);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  async function updatePortolio({}) {
    try {
      setLoading(true);

      const updates = {
        id: user.id,
        layout: {
          mainColor,
          backgroundColor,
          projects,
          clients,
          acomplishments,
          coffee,
          sliderString1,
          sliderString2,
          sliderString3,
        },
        updated_at: new Date(),
      };

      let { error } = await supabase.from("portfolio").upsert(updates, {
        returning: "minimal", // Don't return the value after inserting
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Page Design</h1>
      </div>

      <form>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Dark color</label>
            <div>
              <Swatch
                onClick={() => {
                  setDisplayMainColor(true);
                }}
              >
                <Color style={{ background: `${mainColor}` }} />
              </Swatch>
              {displayMainColor ? (
                <Popover>
                  <Cover
                    onClick={() => {
                      setDisplayMainColor(false);
                    }}
                  />
                  <ChromePicker
                    color={mainColor}
                    onChange={(color) => {
                      setMainColor(color.hex);
                    }}
                  />
                </Popover>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Light background color</label>
            <div>
              <Swatch
                onClick={() => {
                  setDisplayBackgroundcolor(true);
                }}
              >
                <Color style={{ background: `${backgroundColor}` }} />
              </Swatch>
              {displayBackgroundcolor ? (
                <Popover>
                  <Cover
                    onClick={() => {
                      setDisplayBackgroundcolor(false);
                    }}
                  />
                  <ChromePicker
                    color={backgroundColor}
                    onChange={(color) => {
                      setBackgroundColor(color.hex);
                    }}
                  />
                </Popover>
              ) : null}
            </div>
          </div>
        </div>
        {/* <ChromePicker
          color={backgroundColor}
          onChangeComplete={(color) => {
            setBackgroundColor(color.hex);
          }}
        /> */}
        <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
          <h1 className="h3 mb-2 text-nowrap">Counter</h1>
        </div>

        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">
              Number of completed projects
            </label>
            <div>
              <NumberPicker
                value={projects}
                onChange={(value) => setProjects(value)}
                min={0}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">
              Number of certificates and awards
            </label>
            <div>
              <NumberPicker
                value={acomplishments}
                onChange={(value) => setAcomplishments(value)}
                min={0}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Number of clients</label>
            <div>
              <NumberPicker
                value={clients}
                onChange={(value) => setClients(value)}
                min={0}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Number of cups of coffee</label>
            <div>
              <NumberPicker
                value={coffee}
                onChange={(value) => setCoffee(value)}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
          <h1 className="h3 mb-2 text-nowrap">Animated titles</h1>
        </div>

        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">I'am " "</label>
            <input
              placeholder="Designer"
              className="form-control"
              value={sliderString1}
              onChange={(e) => {
                setSliderString1(e.target.value);
              }}
            />
            <input
              placeholder="Photographer"
              className="form-control"
              value={sliderString2}
              onChange={(e) => {
                setSliderString2(e.target.value);
              }}
            />
            <input
              placeholder="Content writer"
              className="form-control"
              value={sliderString3}
              onChange={(e) => {
                setSliderString3(e.target.value);
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() =>
            updatePortolio({
              mainColor,
              backgroundColor,
              projects,
              acomplishments,
              clients,
              coffee,
              sliderString1,
              sliderString2,
              sliderString3,
            })
          }
          disabled={loading}
          type="submit"
        >
          {loading ? "Loading ..." : "save"}
        </button>
      </form>
    </div>
  );
};

const Swatch = styled.div`
  padding: 5px;
  background: #766df4;
  border-radius: 4px;
  boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
`;

const Popover = styled.div`
  z-index: 3;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

// const styles = reactCSS({
//   default: {
//     color: {
//       width: "36px",
//       height: "14px",
//       borderRadius: "2px",
//       background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
//     },
//     swatch: {
//       padding: "5px",
//       background: "#fff",
//       borderRadius: "1px",
//       boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
//       display: "inline-block",
//       cursor: "pointer",
//     },
//     popover: {
//       position: "absolute",
//       zIndex: "2",
//     },
//     cover: {
//       position: "fixed",
//       top: "0px",
//       right: "0px",
//       bottom: "0px",
//       left: "0px",
//     },
//   },
// });

export default PageStyle;
