import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Pricebox from "../elements/Pricebox";

function Pricing({ priceboxData, home }) {
  return (
    <section id="prices">
      <div className="container">
        <div className="row align-items-center">
          {priceboxData.map((pricebox) => (
            <div key={pricebox.id} className="">
              <Pricebox priceboxItem={pricebox} home={home} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
