import { React, useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { supabase } from "../../supabaseClient";

import AddMedia from "../Utils/AddMedia";

const AboutMe = () => {
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(true);
  const [media_url, setMediaUrl] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`about`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data.about) {
          setName(data.about.name);
          setAbout(data.about.about);
        } else {
          setName("");
          setAbout("");
        }
        if (data.about.media_url) {
          setMediaUrl(data.about.media_url);
        }
        if (data.about.cv) {
          setMediaUrl(data.about.cv);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  async function updatePortolio({ name, about, media_url, cv }) {
    try {
      setLoading(true);

      const updates = {
        id: user.id,
        about: {
          name: name,
          about: about,
          media_url,
          cv,
        },
        updated_at: new Date(),
      };

      let { error } = await supabase.from("portfolio").upsert(updates, {
        returning: "minimal", // Don't return the value after inserting
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }
  async function uploadImage(url) {
    try {
      const { publicURL, error } = await supabase.storage
        .from("portfoliomedia")
        .getPublicUrl(url);
      if (error) {
        throw error;
      }

      setMediaUrl(publicURL);
    } catch (error) {
      console.log("Error downloading image: ", error.message);
    }
  }

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Brief About Me</h1>
      </div>
      <AddMedia
        url={media_url}
        size={150}
        onUpload={(url) => {
          uploadImage(url);
        }}
      />
      {/* <div className="bg-secondary rounded-3 p-4 mb-4">
        <div className="d-block d-sm-flex align-items-center">
          <img
            alt="df"
            className="d-block rounded-circle mx-sm-0 mx-auto mb-3 mb-sm-0"
            src="https://odohjzdxsjutbhvcenhk.supabase.co/storage/v1/object/sign/avatars/0.8472440568486472.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJhdmF0YXJzLzAuODQ3MjQ0MDU2ODQ4NjQ3Mi5wbmciLCJpYXQiOjE2MjUzNDQ4NTAsImV4cCI6MTk0MDcwNDg1MH0.F_TPrT4C1F586Jzt66zy2QCw1Dd9WiyzWJn5m7g3wJ4"
            width="110"
          ></img>

          <div className="ps-sm-3 text-center text-sm-start">
            <button className="btn btn-light shadow btn-sm mb-2" type="button">
              <i className="ai-refresh-cw me-2"></i>تغير الصورة الشخصية
            </button>
            <div className="p mb-0 fs-ms text-muted">
              Upload JPG, GIF or PNG image. 300 x 300 required.
            </div>
          </div>
        </div>
      </div> */}
      <form>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Your name</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">General Information:</label>
            <textarea
              placeholder="I'am Joe I'm a developer..."
              className="form-control"
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
            />
          </div>
        </div>

        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() => updatePortolio({ name, about, media_url })}
          disabled={loading}
          type="submit"
        >
          {loading ? "Loading ..." : "Update"}
        </button>
      </form>
    </div>
  );
};

export default AboutMe;
