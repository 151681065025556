import React, { useEffect } from "react";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAuth } from "../../context/Auth";
import { useTranslation } from "react-i18next";
import { changeLanguage } from '../../i18n';

const HomeNav = () => {
  const [t, i18n] = useTranslation("global")
  const [show, setShow] = React.useState(false);

  useEffect(()=> {
    document.body.dir = i18n.dir()
  }, [i18n,i18n.language])

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const { user } = useAuth();

  return (
    <div style={{ background: "darkslategray" }}>
      <Navbar xpand="lg">
        <Container>
          <Link to={`/`}>
            <Navbar>
              <h3 style={{ color: "white" }}>{t("landing_page.nav.title")}</h3>
            </Navbar>
          </Link>
          <NavDropdown
          id="nav-dropdown-dark-example"
          style={{ textColor: "white" }}
          menuVariant="dark"
          title="Lang"
          >   <NavDropdown.Item onClick={() => changeLanguage("en")}>EN</NavDropdown.Item>
          <NavDropdown.Item onClick={() => changeLanguage("ar")}>AR</NavDropdown.Item>

          </NavDropdown>
    
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {user ? (
                <Link className="nav-link" to={`/dashboard/${user.id}`}>
                  <h3 style={{ color: "white" }}>{t("landing_page.nav.dashboard")}</h3>
                </Link>
              ) : (
                <Link className="nav-link" to="/login">
                  <h3 style={{ color: "white" }}>{t("landing_page.nav.login")}</h3>
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default HomeNav;
