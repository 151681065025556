import React from "react";

function Footer({ light }) {
  return (
    <footer className="footer light">
      <div className="container">
        <p>
          {" "}
          support:{" "}
          <a href="mailto:info.mypage.me@gmail.com">info.mypage.me@gmail.com</a>
        </p>
        <span className="copyright">
          &copy; {new Date().getFullYear()}my-page.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
