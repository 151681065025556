import { React, useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { supabase } from "../../supabaseClient";

import Pricing from "../userPage/components/sections/Pricing";

const Plan = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);
        let array = [];
        let { data, error, status } = await supabase
          .from("plan")
          .select(`plandata`);
        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          // array.push(data[2].plandata, data[0].plandata, data[1].plandata);
          for (var i = 0; i < data.length; i++) {
            if (i !== 1) {
              array.push(data[i].plandata);
            }
          }

          setPlan(array);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Plan</h1>
      </div>

      <div>{plan ? <Pricing priceboxData={plan} /> : null}</div>
    </div>
  );
};

export default Plan;
