import { React, useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { supabase } from "../../supabaseClient";
import Datetime from "react-datetime";
import ScrollAnimation from "react-animate-on-scroll";

import "react-datetime/css/react-datetime.css";

const Experience = () => {
  const [Experiences, setExperiences] = useState([]);

  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`experiences`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data.experiences) {
          setExperiences(data.experiences);
        } else {
          setExperiences([]);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  async function updateExperience({ startDate, EndDate, title, about }) {
    if (!startDate) {
      setError(true);
      setMessage("Duration is mandatory");
    } else if (!EndDate) {
      setError(true);
      setMessage("Duration is mandatory");
    } else if (!title) {
      setError(true);
      setMessage("Title is mandatory");
    } else {
      setError(false);
      setMessage("Experience added successfully");

      try {
        setLoading(true);

        const updates = {
          id: user.id,
          experiences: [
            ...Experiences,
            {
              from: startDate,
              to: EndDate,
              title: title,
              about: about,
              updated_at: new Date(),
            },
          ],
          updated_at: new Date(),
        };

        let { data, error } = await supabase.from("portfolio").upsert(updates, {
          returning: "representation", // Don't return the value after inserting
        }); // then get the data and add to state

        if (data) {
          setExperiences(data[0].experiences);
          setAbout("");

          setTitle("");
        }
        if (error) {
          throw error;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  const handleDelete = async (e) => {
    var array = Experiences;
    if (e !== -1) {
      array.splice(e, 1);
      try {
        setLoading(true);

        const updates = {
          id: user.id,
          experiences: array,
          updated_at: new Date(),
        };

        let { data, error } = await supabase
          .from("portfolio")
          .update(updates, {
            returning: "representation", // Don't return the value after inserting
          })
          .match({ id: user.id }); // then get the data and add to state

        if (data) {
          setExperiences(data[0].experiences);
        }
        if (error) {
          throw error;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">My Experience</h1>
      </div>
      <form>
        <div className="row">
          <div className="col-sm">
            <div className="mb-3 pb-1">
              <label className="form-label px-0">From</label>

              <Datetime
                dateFormat="YYYY"
                onChange={(date) => {
                  setStartDate(date.year());
                }}
                timeFormat={false}
                inputProps={{
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                }}
              />
              {/* <input
                className="form-control"
                value={from}
                onChange={(e) => {
                  const re = /^[+-]?\d*(?:[.,]\d*)?$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (e.target.value.length < 5) setFrom(e.target.value);
                  }
                }}
              /> */}
            </div>
          </div>
          <div className="col-sm">
            <div className="mb-3 pb-1">
              <label className="form-label px-0">To</label>

              <Datetime
                dateFormat="YYYY"
                onChange={(date) => {
                  setEndDate(date.year());
                }}
                timeFormat={false}
                inputProps={{
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                }}
              />
              {/* <input
                className="form-control"
                value={to}
                maxlength="4"
                onChange={(e) => {
                  const re = /^[+-]?\d*(?:[.,]\d*)?$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (e.target.value.length < 5) setTo(e.target.value);
                  }
                }}
              /> */}
            </div>
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Title</label>
            <input
              className="form-control"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Description</label>
            <textarea
              placeholder="I worked in development..."
              className="form-control"
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() => updateExperience({ startDate, EndDate, title, about })}
          disabled={loading}
          type="submit"
        >
          {loading ? "Loading ..." : "Add"}
        </button>
      </form>
      {handleAlerts()}
      <div>
        {Experiences.map((data, index) => (
          <ScrollAnimation
            key={index}
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
            style={{ background: "ghostwhite", margin: "5px" }}
            className="row"
          >
            <div className="timeline-container col-8">
              <div className="content">
                <span className="time">
                  {data.from} - {data.to}
                </span>
                <h3 className="title">{data.title}</h3>
              </div>
            </div>
            <div
              className="col-4"
              style={{ alignSelf: "center", textAlign: "center" }}
            >
              <button
                className="btn btn-light shadow btn-sm mb-2"
                onClick={() => handleDelete(index)}
              >
                Delete
              </button>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </div>
  );
};

export default Experience;
