import React, { useState } from "react";
import HomeNav from "./HomeNav";
import Homepage3Light from "../userPage/pages/Homepage3Light";
import "./Home.css";
export default function Home() {
  return (
    <>
      <HomeNav />
      <Homepage3Light />
    </>
  );
}
