import { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import { useAuth } from "../../context/Auth";
import Avatar from "./Avatar";

export default function Account() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState();
  const [avatar_url, setAvatarUrl] = useState(null);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`username, avatar_url`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          console.log("يجب تسجيل اسم المستخدم اولا");
        }

        if (data.username) {
          setUsername(data.username);
        } else {
          setUsername("");
        }

        if (data.avatar_url) {
          setAvatarUrl(data.avatar_url);
        } else {
          setAvatarUrl(null);
        }
      } catch (error) {
        setError(true);

        setMessage("Username must be added first to show your page");
      } finally {
        setLoading(false);
      }
    };
    getProfile();
  }, [user]);

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };
  async function updateProfile({ username, avatar_url }) {
    try {
      setLoading(true);

      const updates = {
        id: user.id,
        username,

        avatar_url,
        updated_at: new Date(),
      };

      let { error } = await supabase.from("portfolio").upsert(updates, {
        returning: "minimal", // Don't return the value after inserting
      });

      if (error) {
        setError(true);
        setMessage("You must choose a username that does not already exist");
      } else {
        setMessage("Username changed successfully");
        setError(false);
        window.location.reload(false);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  // async function updateProfile({ username, avatar_url }) {
  //   try {
  //     setLoading(true);

  //     const updates = {
  //       id: user.id,
  //       username,

  //       avatar_url,
  //       updated_at: new Date(),
  //     };

  //     let { error } = await supabase.from("profiles").upsert(updates, {
  //       returning: "minimal", // Don't return the value after inserting
  //     });

  //     if (error) {
  //       let M = error.message;
  //       if (M.includes("profiles_username_key")) {
  //         setError(true);
  //         setMessage("يجب اختيار اسم غير موجود مسبقا");
  //       }
  //     } else {
  //       setMessage("تم تغير اسم المستخدم بنجاح");
  //       setError(false);
  //     }
  //   } catch (error) {
  //     alert(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Account Information</h1>
      </div>
      <Avatar
        url={avatar_url}
        size={150}
        onUpload={(url) => {
          setAvatarUrl(url);
          updateProfile({ username, avatar_url: url });
        }}
      />

      <div className="mb-3 pb-1">
        <label className="form-label px-0" htmlFor="email">
          email
        </label>
        <input
          className="form-control"
          id="email"
          type="text"
          value={user.email}
          disabled
        />
      </div>
      <div className="mb-3 pb-1">
        <label className="form-label px-0" htmlFor="username">
          username
        </label>
        <input
          className="form-control"
          id="username"
          type="text"
          value={username ? username : ""}
          onChange={(e) => setUsername(e.target.value)}
        />
        Your username will be your url address for your page...
      </div>

      <div>
        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() => updateProfile({ username, avatar_url })}
          disabled={loading}
          type="submit"
        >
          {loading ? "Loading ..." : "Update"}
        </button>

        {handleAlerts()}
      </div>
    </div>
  );
}
