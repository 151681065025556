import { Link, useLocation } from "react-router-dom";
import { supabase } from "../../../supabaseClient";
import { useAuth } from "../../../context/Auth";
import { useState, useEffect } from "react";
import Cards from "../../Utils/Cards";
import styled from "styled-components";

/* TODO:
 * []
 */

const Portfolios = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("");
  const [website, setWebsite] = useState("");
  const [portfolios, setPortfolios] = useState();
  const [media_url, setMediaUrl] = useState(null);

  const location = useLocation();

  const { user } = useAuth();

  useEffect(() => {
    let array = [];

    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`portfolios`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          console.log(error);
        }

        if (data.portfolios) {
          setPortfolios(data.portfolios);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user, location]);

  const handleDelete = async (e) => {
    var array = portfolios;
    if (e !== -1) {
      array.splice(e, 1);
      try {
        setLoading(true);

        const updates = {
          id: user.id,
          portfolios: array,
          updated_at: new Date(),
        };

        let { data, error } = await supabase
          .from("portfolio")
          .update(updates, {
            returning: "representation", // Don't return the value after inserting
          })
          .match({ id: user.id }); // then get the data and add to state

        if (data) {
          setPortfolios(data[0].portfolios);
        }
        if (error) {
          throw error;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">My works</h1>
      </div>
      <Link
        className="col-lg-6 col-md-4 col-sm-12"
        to="/dashboard/:id/addportfolio"
      >
        <div>
          <div>
            <button className="btn btn-default" btn btn-default>
              {" "}
              Add
            </button>
          </div>
        </div>
      </Link>
      <div className="container">
        <div className="row clearfix">
          {portfolios
            ? portfolios.map((portfolio, index) => {
                return (
                  <CardsSection
                    key={index}
                    className="col-lg-6 col-md-4 col-sm-12"
                  >
                    <DeleteButton onClick={() => handleDelete(index)}>
                      x
                    </DeleteButton>
                    <Cards
                      media_url={portfolio.media_url}
                      title={portfolio.title}
                      index={index}
                    />
                  </CardsSection>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Portfolios;
// {
//   portfolios
//     ? portfolios.map(async (portfolio) => {
//         if (portfolio.media_url) {
//           console.log("dd", portfolio.media_url);
//           let media;
//           try {
//             const { data, error } = await supabase.storage
//               .from("portfoliomedia")
//               .download(portfolio.media_url);
//             if (error) {
//               throw error;
//             }
//             const url = URL.createObjectURL(data);
//             media = url;
//           } catch (error) {
//             console.log("Error downloading image: ", error.message);
//           }
//         }

//         console.log(portfolio.title);
//         return (
//           <div className="col-lg-6 col-md-4 col-sm-12">
//             <div className="card product_item">
//               <div className="body">
//                 <div className="cp_img">
//                   <img src={bg} alt="Product" className="img-fluid" />
//                 </div>
//                 <div className="product_details">
//                   <h5>
//                     <a href="ec-product-detail.html">{portfolio.title}</a>
//                   </h5>
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       })
//     : "";
// }

{
  /* <div className="card product_item">
                        <div className="body">
                          <div className="h-75 d-sm-block cp_img">
                            <img
                              src={portfolio.media_url}
                              alt="Product"
                              className="img-thumbnail "
                            />
                          </div>
                          <div className="h-25 d-inline-block product_details">
                            <h5>
                              <a href="ec-product-detail.html">
                                {portfolio.title}
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div> */
}
const DeleteButton = styled.button`
  cursor: pointer;
  margin-top: 30px;
  padding-bottom: -100px;
  /* margin-bottom: -20px; */
  background-color: transparent !important;
  border: transparent !important;
  height: 30px;
  width: 30px;
  background-color: #bbb;
  border-radius: 50%;
  border-color: inherit;
  /* display: inline-block; */
  z-index: 10;
`;
const CardsSection = styled.div`
  .dd {
    text-align-last: center;
  }
  img {
    width: 70% !important;
    height: 70% !important;
  }
  .card {
    margin: 10px !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    border-radius: 0.75rem;
    box-sizing: border-box;
    border: 0 solid #eee;
  }
`;
