import { useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";

export default function Avatar({ url, size, onUpload }) {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (url) downloadImage(url);
  }, [url]);

  async function downloadImage(path) {
    try {
      const { data, error } = await supabase.storage
        .from("avatars")
        .download(path);
      if (error) {
        throw error;
      }
      const url = URL.createObjectURL(data);
      setAvatarUrl(url);
    } catch (error) {
      console.log("Error downloading image: ", error.message);
    }
  }

  async function uploadAvatar(event) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      let { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      onUpload(filePath);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div>
      <div className="bg-secondary rounded-3 p-4 mb-4">
        <div className="d-block d-sm-flex align-items-center">
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt="Avatar"
              className="d-block rounded-circle mx-sm-0 mx-auto mb-3 mb-sm-0"
              style={{ height: size, width: size }}
            />
          ) : (
            <div
              className="avatar no-image"
              style={{ height: size, width: size }}
            />
          )}

          <div className="ps-sm-3 text-center text-sm-start">
            <label
              className="btn btn-light shadow btn-sm mb-2"
              htmlFor="single"
            >
              {uploading ? "Loading ..." : "change profile picture"}
            </label>
            <input
              style={{
                visibility: "hidden",
                position: "absolute",
              }}
              type="file"
              id="single"
              accept="image/*"
              onChange={uploadAvatar}
              disabled={uploading}
            />
            <div className="p mb-0 fs-ms text-muted">
              Upload JPG, GIF or PNG image. 300 x 300 required.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
