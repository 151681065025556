import React, { useEffect, useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Footer from "../components/layouts/Footer";
import AboutHomePage from "../components/sections/AboutHomePage";

import Herosection from "../components/sections/HerosectionLight";
import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";

import { supabase } from "../../../supabaseClient";


function Homepage3Light() {
  document.body.classList.remove("dark");
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);
        let array = [];
        let { data, error, status } = await supabase
          .from("plan")
          .select(`plandata`);
        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          // array.push(data[2].plandata, data[0].plandata, data[1].plandata);
          for (var i = 0; i < data.length; i++) {
            if (i !== 1) {
              array.push(data[i].plandata);
            }
          }

          setPlan(array);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, []);
  return (
    <>
      {/* <Header3 light logoSource="/images/logo-dark.svg" /> */}
      <main className="content-3">
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection light />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <AboutHomePage />
        </Element>
        <div className="container">
          <div className="row">
            <div className="col-sm">
              Dashboard
              <img
                className="imgHome"
                style={{ height: "80%", borderRadius: "15px" }}
                src={"images/dashboard.png"}
              />
            </div>
            <div className="col-sm">
              Your page
              <img
                className="imgHome"
                style={{ height: "80%", borderRadius: "15px" }}
                src={"images/userpage.png"}
              />
            </div>
          </div>
        </div>
        <Element name="section-services">
          <Services />
        </Element>

        {/* <Element name="section-experiences">
          <Experiences />
        </Element>
        <Element name="section-works">
          <Works />
        </Element>
        <Element name="section-pricing">
          <Pricing />
        </Element>
        <Element name="section-testimoninal">
          <Testimonials />
        </Element>
        <Element name="section-brandlogos">
          <Brandlogos />
        </Element>
        <Element name="section-blogs">
          <Blogs />
        </Element>
        <Element name="section-contact">
          <Contact />
        </Element> */}
        <Element name="section-pricing">
          {plan ? <Pricing priceboxData={plan} home={true} /> : null}
        </Element>
        <div className="spacer" data-height="96"></div>
      </main>
      <Footer light />
    </>
  );
}

export default Homepage3Light;
