import { useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { useAuth } from "../context/Auth";
import HomeNav from "./home/HomeNav";
export function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");

  const { signUp } = useAuth();
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const { error } = await signUp({ email, password });

    if (error) {
      setError(error);
      setMessage("error with email or password");
      return;
    }

    history.push("/");
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <>
      <HomeNav />
      <div className="container">
        <br />
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="input-email">Email</label>
          <input
            className="form-control"
            id="input-email"
            type="email"
            ref={emailRef}
          />

          <label htmlFor="input-password">Password</label>
          <input
            className="form-control"
            id="input-password"
            type="password"
            ref={passwordRef}
          />

          <br />

          <button className="btn btn-light shadow btn-sm mb-2" type="submit">
            Sign up
          </button>
        </form>

        <br />

        <p>
          Already have an account? <Link to="/login">Log In</Link>
        </p>

        {handleAlerts()}
      </div>
    </>
  );
}
