import { React, useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { supabase } from "../../supabaseClient";

const ContactMe = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [pinterest, setPinterest] = useState("");
  const [dribbble, setDribbble] = useState("");
  const [behance, setBehance] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const [loading, setLoading] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setLoading(true);

        let { data, error, status } = await supabase
          .from("portfolio")
          .select(`contact`)
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          throw error;
        }

        if (data.contact) {
          setEmail(data.contact.email);
          setPhone(data.contact.phone);
          setInstagram(data.contact.instagram);
          setTwitter(data.contact.twitter);
          setPinterest(data.contact.pinterest);
          setDribbble(data.contact.dribbble);
          setBehance(data.contact.behance);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getPortfolio();
  }, [user]);

  async function updatePortolio({
    email,
    phone,
    twitter,
    behance,
    instagram,
    dribbble,
    pinterest,
    linkedin,
  }) {
    try {
      setLoading(true);

      const updates = {
        id: user.id,
        contact: {
          email,
          phone,
          twitter,
          behance,
          instagram,
          dribbble,
          pinterest,
          linkedin,
        },
        updated_at: new Date(),
      };

      let { error } = await supabase.from("portfolio").upsert(updates, {
        returning: "minimal", // Don't return the value after inserting
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="py-2 p-md-3">
      <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
        <h1 className="h3 mb-2 text-nowrap">Contact</h1>
      </div>

      <form>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Email</label>
            <input
              className="form-control"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Phone</label>
            <input
              type="phone"
              className="form-control"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">linkedin</label>
            <input
              type="phone"
              className="form-control"
              onChange={(e) => {
                setLinkedin(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Instagram</label>
            <input
              type="instagram"
              className="form-control"
              value={instagram}
              onChange={(e) => {
                setInstagram(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Twitter</label>
            <input
              className="form-control"
              value={twitter}
              onChange={(e) => {
                setTwitter(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Pinterest</label>
            <input
              className="form-control"
              value={pinterest}
              onChange={(e) => {
                setPinterest(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Behance</label>
            <input
              className="form-control"
              value={behance}
              onChange={(e) => {
                setBehance(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className="mb-3 pb-1">
            <label className="form-label px-0">Dribbble</label>
            <input
              className="form-control"
              value={dribbble}
              onChange={(e) => {
                setDribbble(e.target.value);
              }}
            />
          </div>
        </div>

        <button
          className="btn btn-light shadow btn-sm mb-2"
          onClick={() =>
            updatePortolio({
              email,
              phone,
              twitter,
              behance,
              instagram,
              dribbble,
              pinterest,
              linkedin,
            })
          }
          disabled={loading}
          type="submit"
        >
          {loading ? "Loading ..." : "save"}
        </button>
      </form>
    </div>
  );
};

export default ContactMe;
