import { useRef, useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";

import { useAuth } from "../context/Auth";
import HomeNav from "./home/HomeNav";
export default function Login() {
  const { user } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState(null);

  const [message, setMessage] = useState("");

  const { signIn } = useAuth();
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const { error } = await signIn({ email, password });

    if (error) {
      setError(error);
      setMessage("error with email or password");
      return;
    }

    history.push("/");
  }
  async function signInWithGoogle() {
    const { error } = await signIn({ provider: "google" });
    if (error) {
      setError(error);
      setMessage("error with email or password");
      return;
    }
  }

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <>
      {user ? (
        <Redirect to="/" />
      ) : (
        <>
          <HomeNav />
          <div className="container">
            <br />

            <h2>Sign In</h2>
            {/* <form onSubmit={handleSubmit}>
              <label htmlFor="input-email">Email</label>
              <input
                className="form-control"
                id="input-email"
                type="email"
                ref={emailRef}
              />

              <label htmlFor="input-password">Password</label>
              <input
                className="form-control"
                id="input-password"
                type="password"
                ref={passwordRef}
              />

              <br />

              <button
                className="btn btn-light shadow btn-sm mb-2"
                type="submit"
              >
                Login
              </button>
            </form>
            <br />
            <p>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </p> */}
            {handleAlerts()}
            <div class="google-btn" onClick={signInWithGoogle}>
              <div class="google-icon-wrapper">
                <img
                  class="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                />
              </div>
              <p class="btn-text">
                <b>Sign in with google</b>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
